<template>
	<div class="floor-style-2" style="">
		
		<div class="title1 title5" v-if="data.value.title.value.text" >
			<h4 @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.title.value.color }" style="color: #ff4c3b;
    color: var(#ff9944);
    padding-bottom: 10px;
    margin-bottom: 0;
    margin-top: -3px; font-family: 'sukhumvittadmai', sans-serif !important;  " >{{ data.value.title.value.text }}</h4>
			<h2 style=" padding-bottom: 10px; font-weight: 500;" @click="$router.pushToTab(data.value.subTitle.value.link.url)" :style="{ color: data.value.subTitle.value.color }"  class="title-inner1">{{ data.value.subTitle.value.text }}</h2>
			<hr  role="tournament6" style="   border: 0px solid;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #ff4c3b, rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #ff9944, rgba(0, 0, 0, 0));
    display: block;
    width: 30%; 
    margin: 15px auto 30px auto;">
		</div>
		<div class="body-wrap">
			
			<div class="product-card__wrapper items-1-5" style="     justify-content: flex-start;
			    display: flex; padding-top: 20px;
			">
			                        <div class="product-card" v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)" style="background: #fff;
    padding: 10px;
    border-radius: 15px;">
			                            <div class="product__image__wrapper">
			                                <a  class="product__image">
			                                    <img :src="$img(item.goods_image.split(',')[0], {size: 'mid'})" @error="imageError(index)" alt="icon" style="width: 100%;     height: 100%;">
			                                </a>
			                               
			                                <div class="product__actions">
			                                    <a href="#" class="action__btn">
			                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			                                            <path d="M13.8931 2.07333C13.5526 1.73267 13.1483 1.46243 12.7033 1.27805C12.2584 1.09368 11.7814 0.998779 11.2998 0.998779C10.8181 0.998779 10.3412 1.09368 9.89618 1.27805C9.45121 1.46243 9.04692 1.73267 8.70642 2.07333L7.99975 2.78L7.29309 2.07333C6.60529 1.38553 5.67244 0.999136 4.69975 0.999136C3.72706 0.999136 2.79422 1.38553 2.10642 2.07333C1.41863 2.76112 1.03223 3.69397 1.03223 4.66666C1.03223 5.63935 1.41863 6.5722 2.10642 7.26L2.81309 7.96666L7.99975 13.1533L13.1864 7.96666L13.8931 7.26C14.2337 6.91949 14.504 6.51521 14.6884 6.07023C14.8727 5.62526 14.9676 5.14832 14.9676 4.66666C14.9676 4.185 14.8727 3.70807 14.6884 3.26309C14.504 2.81812 14.2337 2.41383 13.8931 2.07333V2.07333Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
			                                        </svg>
			                                    </a>
			                                    <a href="#" class="action__btn">
			                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			                                            <path d="M0.666992 7.99996C0.666992 7.99996 3.33366 2.66663 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C15.3337 7.99996 12.667 13.3333 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
			                                            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
			                                        </svg>
			                                    </a>
			                                    <a href="#" class="action__btn">
			                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			                                            <path d="M10 9.33329L13.3333 5.99996L10 2.66663" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
			                                            <path d="M2.66699 13.3333V8.66667C2.66699 7.95942 2.94794 7.28115 3.44804 6.78105C3.94814 6.28095 4.62641 6 5.33366 6H13.3337" stroke="#252522" stroke-linecap="round" stroke-linejoin="round"></path>
			                                        </svg>
			                                    </a>
			                                </div>
			                            </div>
			                            <div class="product__content">
			                                
			                                <div class="product__title" style="min-height: 44px;
    max-height: 44px;">
			                                    <h5><a style="font-family: 'sukhumvittadmai', sans-serif !important;" >{{ item.goods_name }}</a></h5>
			                                </div>
			                                <div class="product__bottom">
			                                    <div class="product__price">
			                                        ฿{{ item.discount_price }}
			                                        <del>฿{{ item.market_price }}</del>
			                                    </div>
			                                    <div class="cart__action__btn">
			                                        <div class="cart__btn">
			                                            <a href="#" class="btn btn-outline"  style="font-family: 'sukhumvittadmai', sans-serif !important; background: #a7a7a7; color: #fff;">ซื้อเลย</a>
			                                        </div>
			                                        <div class="quantity cart__quantity">
			                                            <button type="button" class="decressQnt">
			                                                <span class="bar"></span>
			                                            </button>
			                                            <input class="qnttinput" type="number" disabled="" value="0" min="01" max="100">
			                                            <button type="button" class="incressQnt">
			                                                <span class="bar"></span>
			                                            </button>
			                                        </div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                        
			                        
			                        
			                        
			                        
			                        
			                        
			                        
			                        
			                        
			                        
			                        
			                        
			                        
			                    </div>
			
			
			
			<ul class="goods-list" style="display: none">
				<li v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
					<div class="img-wrap"><img alt="รูปภาพสินค้า" :src="$img(item.goods_image.split(',')[0], {size: 'mid'})" @error="imageError(index)" /></div>
					<h3>{{ item.goods_name }}</h3>
					<p class="desc">{{ item.introduction }}</p>
					<p class="price">
						<span class="num">{{ item.discount_price }} บาท</span>
						<del>{{ item.market_price }} บาท</del>
					</p>
				</li>
			</ul>
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomImg.value.url" style="    margin-top: 55px;
    margin-bottom: 0px;
    width: 1400px;
   height: 358px;
    cursor: pointer;
    overflow: hidden;">
			<img :src="$img(data.value.bottomImg.value.url)" @click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-2',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-2 {
	.head-wrap {
		text-align: center;
		h2 {
			line-height: 30px;
			color: #333;
			padding: 10px;
			font-size: 22px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		p {
			color: #b0b0b0;
			padding: 0 10px;
			font-size: 14px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-bottom: 20px;
		}
	}
	.body-wrap {
		.goods-list {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 19%;
				margin-left: 15px;
				margin-bottom: 15px;
				background: #fff;
				cursor: pointer;
				padding: 10px 0;
				transition: all 0.2s linear;
				&:nth-child(5n + 1) {
					margin-left: 0;
				}
				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
				}
				.img-wrap {
					width: 160px;
					height: 160px;
					margin: 0 auto 18px;
					text-align: center;
					line-height: 160px;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				h3 {
					font-size: 14px;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin: 5px 15px;
				}
				.desc {
					margin: 0 30px 10px;
					height: 20px;
					font-size: 12px;
					color: #b0b0b0;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.price {
					margin: 0 10px 14px;
					text-align: center;
					color: $base-color;
					del {
						margin-left: 0.5em;
						color: #b0b0b0;
					}
				}
			}
		}
	}
	.bottom-wrap {
		margin-top: 10px;
		width: $width;
		height: 118px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
}/*! CSS Used from: https://angular.pixelstrap.com/multikart/styles.css */
*,*::before,*::after{box-sizing:border-box;}
hr{margin:1rem 0;color:inherit;background-color:currentColor;border:0;opacity:0.25;}
hr:not([size]){height:1px;}
h4,h2{margin-top:0;margin-bottom:0.5rem;font-weight:500;line-height:1.2;}
h2{font-size:calc(1.325rem + 0.9vw);}
@media (min-width: 1200px){
h2{font-size:2rem;}
}
h4{font-size:calc(1.275rem + 0.3vw);}
@media (min-width: 1200px){
h4{font-size:1.5rem;}
}
::-moz-focus-inner{padding:0;border-style:none;}
h2{font-size:36px;color:#222222;text-transform:uppercase;font-weight:700;line-height:1;letter-spacing:0.02em;}
h4{font-size:18px;text-transform:capitalize;font-weight:400;letter-spacing:0.03em;line-height:1;}
:focus{outline:initial;}
.title1{text-align:center;}
.title1 h4{color:#ff4c3b;color:var(--theme-deafult);padding-bottom:10px;margin-bottom:0;margin-top:-3px;}
.title1 .title-inner1{padding-bottom:15px;margin-bottom:30px;position:relative;}
.title1 .title-inner1:after{position:absolute;height:5px;width:70px;background-color:#ff4c3b;background-color:var(--theme-deafult);content:"";left:0;right:0;margin:0 auto;bottom:0;}
.title1.title5 .title-inner1{padding-bottom:0;margin-bottom:0;}
.title1.title5 .title-inner1:after{display:none;}
.title1.title5 hr[role=tournament6]{border:0px solid;height:1px;background-image:linear-gradient(to right, rgba(0, 0, 0, 0), #ff4c3b, rgba(0, 0, 0, 0));background-image:linear-gradient(to right, rgba(0, 0, 0, 0), var(--theme-deafult), rgba(0, 0, 0, 0));display:block;width:25%;margin:15px auto 30px auto;}
.title1.title5 hr[role=tournament6]::before{position:absolute;background-color:#efefef;border:1px solid;border-color:#ff4c3b;border-color:#ff4c3b;padding:5px;transform:rotate(45deg);left:50%;margin:-6px 0px 0px 0;content:"";}
@media (max-width: 1367px){
h2{font-size:32px;}
.title1 .title-inner1{padding-bottom:10px;}
.title1 .title-inner1:after{height:2px;}
}
@media (max-width: 1199px){
.title1 h4{padding-bottom:8px;}
.title1 .title-inner1{padding-bottom:12px;margin-bottom:25px;}
}
@media (max-width: 991px){
h2{font-size:28px;}
h4{font-size:16px;}
}
@media (max-width: 577px){
h2{font-size:24px;}
}
@media (max-width: 480px){
.title1 .title-inner1{margin-bottom:20px;}
}
@media (max-width: 420px){
h2{font-size:22px;}
}
@media (max-width: 360px){
h2{font-size:25px;}
}/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/all.min.css */
.fa-regular,.fa-solid{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:var(--fa-display, inline-block);font-style:normal;font-variant:normal;line-height:1;text-rendering:auto;}
.fa-star:before{content:"\f005";}
.fa-regular{font-family:"Font Awesome 6 Free";font-weight:400;}
.fa-solid{font-family:"Font Awesome 6 Free";font-weight:900;}
/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h5{margin-top:0;margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h5{font-size:1.25rem;}
ul{padding-left:2rem;}
ul{margin-top:0;margin-bottom:1rem;}
a{color:#0d6efd;text-decoration:underline;}
a:hover{color:#0a58ca;}
img,svg{vertical-align:middle;}
button{border-radius:0;}
button:focus:not(:focus-visible){outline:0;}
button,input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button{text-transform:none;}
[type=button],button{-webkit-appearance:button;}
::-moz-focus-inner{padding:0;border-style:none;}
.btn{display:inline-block;font-weight:400;line-height:1.5;color:#212529;text-align:center;text-decoration:none;vertical-align:middle;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;padding:.375rem .75rem;font-size:1rem;border-radius:.25rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.btn{transition:none;}
}
.btn:hover{color:#212529;}
.btn:focus{outline:0;box-shadow:0 0 0 .25rem rgba(13,110,253,.25);}
.btn:disabled{pointer-events:none;opacity:.65;}
.badge{display:inline-block;padding:.35em .65em;font-size:.75em;font-weight:700;line-height:1;color:#fff;text-align:center;white-space:nowrap;vertical-align:baseline;border-radius:.25rem;}
.badge:empty{display:none;}
/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/style.css */
*{margin:0;padding:0;-webkit-box-sizing:border-box;box-sizing:border-box;}
a{text-decoration:none;}
a:hover,a:focus{text-decoration:none;outline:0px;}
button:hover,button:focus{outline:0px;}
h5{font-weight:400;}
img{display:block;width:100%;height:auto;}
ul{margin:0;padding:0;list-style:inherit;}
input[type="number"]{-moz-appearance:textfield!important;}

button:hover,button:focus{outline:0px;}
::-moz-selection{background:#a7a7a7;color:#fff;}
::selection{background:#a7a7a7;color:#fff;}
.btn{font-family:"Prompt", sans-serif;font-size:16px;line-height:24px;font-weight:500;padding:7px 16px;border-radius:8px;border:none;outline:none;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out;}
.btn:focus{-webkit-box-shadow:none;box-shadow:none;outline:none;}
.btn-outline{background-color:transparent;color:#403c39;border:1px solid #eaecf0;}
.btn-outline:focus,.btn-outline:hover{background:#a7a7a7;color:#fff;-webkit-box-shadow:none;box-shadow:none;border-color:#a7a7a7;}
.product-card{margin-bottom:60px;}
.product-card .product__image__wrapper{position:relative;width:100%;aspect-ratio:1/1;background:#f8f8f8;border-radius:16px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
.product-card .product__image__wrapper .product__image{position:relative;height:100%;width:100%;border-radius:20px;overflow:hidden;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
.product-card .product__image__wrapper .product__image img{width:70%;height:70%;-o-object-fit:contain;object-fit:contain;-o-object-position:center;object-position:center;display:block;border-radius:20px;-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out;}
.product-card .product__image__wrapper .product__actions{position:absolute;right:15px;top:15px;}
.product-card .product__image__wrapper .product__actions .action__btn{height:30px;width:30px;color:#403c39;font-size:14px;border-radius:4px;background:#fff;border:1px solid #dddddd;margin-bottom:8px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;position:absolute;right:0;opacity:0;visibility:hidden;-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out;}
.product-card .product__image__wrapper .product__actions .action__btn svg{margin-right:0;}
.product-card .product__image__wrapper .product__actions .action__btn:nth-of-type(1){z-index:1;top:-7px;}
.product-card .product__image__wrapper .product__actions .action__btn:nth-of-type(2){top:0px;}
.product-card .product__image__wrapper .product__actions .action__btn:nth-of-type(3){top:0px;}
.product-card .product__image__wrapper .product__actions .action__btn:last-of-type{margin-bottom:0;}
.product-card .product__image__wrapper .product__actions .action__btn:hover{border-color:#a7a7a7;}
.product-card .product__image__wrapper .badge{font-family:"Prompt", sans-serif;font-size:13px;font-weight:500;line-height:16px;color:#fff;padding:2px 6px;border-radius:6px;background:#a7a7a7;min-width:38px;position:absolute;top:15px;left:15px;}
.product-card .product__content{margin-top:16px;}
.product-card .product__content .product__rating{-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:inherit;-ms-flex-pack:inherit;justify-content:inherit;}
.product-card .product__content .product__rating ul{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:inherit;-ms-flex-pack:inherit;justify-content:inherit;list-style:none;}
.product-card .product__content .product__rating ul li{font-size:11px;color:#ffd600;height:14px;width:14px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
.product-card .product__content .product__title{margin-top:2px;margin-bottom:16px;}
.product-card .product__content .product__title h5{font-family:"Prompt", sans-serif;font-size:16px;font-weight:400;line-height:24px;color:#252522;margin-bottom:0;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;}
@media screen and (max-width: 1440px){
.product-card .product__content .product__title h5{font-size:14px;line-height:22px;}
}
.product-card .product__content .product__title h5 a{color:#252522;display:block;-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out;}
.product-card .product__content .product__bottom{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
.product-card .product__content .product__price{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:inherit;-ms-flex-pack:inherit;justify-content:inherit;font-family:"Prompt", sans-serif;font-size:16px;font-weight:800;line-height:20px;color:#403c39;}
@media screen and (max-width: 1440px){
.product-card .product__content .product__price{font-size:13px;}
}
.product-card .product__content .product__price del{font-size:12px;font-weight:400;line-height:20px;margin-left:6px;display:block;color:#98a2b3;}
@media screen and (max-width: 1440px){
.product-card .product__content .product__price del{font-size:11px;}
}
.product-card .product__content .total__rating{font-family:"Prompt", sans-serif;font-size:10px;font-weight:400;line-height:20px;color:#98a2b3;margin-left:2px;display:inline-block;}
.product-card:hover .product__image img{-webkit-transform:scale(1.1);transform:scale(1.1);}
.product-card:hover .product__title h5 a{color:#a7a7a7;}
.product-card:hover .product__actions .action__btn{opacity:1;visibility:visible;}
.product-card:hover .product__actions .action__btn:nth-of-type(1){top:0px;}
.product-card:hover .product__actions .action__btn:nth-of-type(2){top:38px;}
.product-card:hover .product__actions .action__btn:nth-of-type(3){top:76px;}
.cart__action__btn{position:relative;}
.cart__action__btn .cart__btn .btn{width:120px;height:40px;}
@media screen and (max-width: 1440px){
.cart__action__btn .cart__btn .btn{padding:7px;width:100px;font-size:14px;}
}
.cart__action__btn .cart__quantity{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);display:none;}
@media screen and (max-width: 1440px){
.cart__action__btn .cart__quantity{width:100px;}
}
.product-card__wrapper{-webkit-box-align:inherit;-ms-flex-align:inherit;align-items:inherit;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-left:-15px;margin-right:-15px;}
@media screen and (max-width: 767px){
.product-card__wrapper{margin-left:0;margin-right:0;padding:0 7.5px;}
}
.product-card__wrapper .product-card{-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;width:25%;padding:0 15px;margin-bottom:30px;}
@media screen and (max-width: 575px){
.product-card__wrapper .product-card{padding:0 7.5px;}
}
@media screen and (max-width: 575px){
.product-card__wrapper .product-card{margin-bottom:15px;}
}
.product-card__wrapper.items-1-5{margin-left:-7.5px;margin-right:-7.5px;}
@media screen and (max-width: 767px){
.product-card__wrapper.items-1-5{margin-left:-7.5px;margin-right:-7.5px;padding:0;}
}
@media screen and (max-width: 767px){
.product-card__wrapper.items-1-5{margin-left:0;margin-right:0;}
}
@media screen and (min-width: 1200px){
.product-card__wrapper.items-1-5 .product-card{    width: 242px;
    margin: 10px;}
}
.quantity{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;border-radius:8px;padding:10px;width:120px;height:40px;background:#a7a7a7;color:#fff;}
.quantity > input[type="number"]{border:none;font-size:15px;margin:0;text-align:center;-webkit-box-flex:1;-ms-flex:1;flex:1;width:100%;color:currentColor;background:transparent;-webkit-appearance:none;-moz-appearance:none;appearance:none;outline:none;}
.quantity .incressQnt,.quantity .decressQnt{cursor:pointer;background:transparent;height:15px;width:15px;border:none;outline:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;position:relative;color:currentColor;}
.quantity .incressQnt .bar,.quantity .decressQnt .bar{position:absolute;top:50%;height:2px;width:15px;background:currentColor;-webkit-transform:translateY(-50%);transform:translateY(-50%);right:0px;background-image:none;border-radius:8px;}
@media screen and (max-width: 1440px){
.quantity .incressQnt .bar,.quantity .decressQnt .bar{width:12px;}
}
.quantity .incressQnt .bar::before,.quantity .decressQnt .bar::before{content:"";position:absolute;top:50%;height:15px;width:2px;background:currentColor;-webkit-transform:translateY(-50%);transform:translateY(-50%);right:6px;background-image:none;border-radius:8px;-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out;}
@media screen and (max-width: 1440px){
.quantity .incressQnt .bar::before,.quantity .decressQnt .bar::before{height:12px;right:5px;}
}
.quantity .decressQnt .bar::before{display:none;}
.archive-category .product-card__wrapper{-webkit-box-flex:1;-ms-flex:1;flex:1;}
@media screen and (max-width: 1199px){
.archive-category .product-card__wrapper .product-card{width:33.33%;}
}
@media screen and (max-width: 991px){
.archive-category .product-card__wrapper .product-card{width:50%;}
}
@media screen and (max-width: 767px){
.archive-category .product-card__wrapper .product-card{width:33.33%;}
}
@media screen and (max-width: 575px){
.archive-category .product-card__wrapper .product-card{width:50%;}
}
@media screen and (max-width: 375px){
.archive-category .product-card__wrapper .product-card{width:100%;}
}
.action__btn{font-family:"Prompt", sans-serif;font-size:16px;font-weight:500;line-height:20px;color:#667085;border:none;outline:none;background:transparent;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-ms-flex-wrap:nowrap;flex-wrap:nowrap;width:100%;-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out;}
.action__btn svg{margin-right:6px;-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out;}
.action__btn:focus{border:none;outline:none;}
.action__btn:hover{color:#a7a7a7;}
.action__btn:hover svg path{stroke:#a7a7a7;}
@media print{
*{color:#000000!important;}
}
/*! CSS Used fontfaces */
@font-face{font-family:"Font Awesome 6 Free";font-style:normal;font-weight:400;font-display:block;src:url(https://demo.themeies.com/supermart-html/assets/fonts/webfonts/fa-regular-400.woff2) format("woff2"), url(https://demo.themeies.com/supermart-html/assets/fonts/webfonts/fa-regular-400.ttf) format("truetype");}
@font-face{font-family:"Font Awesome 6 Free";font-style:normal;font-weight:900;font-display:block;src:url(https://demo.themeies.com/supermart-html/assets/fonts/webfonts/fa-solid-900.woff2) format("woff2"), url(https://demo.themeies.com/supermart-html/assets/fonts/webfonts/fa-solid-900.ttf) format("truetype");}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}.cart__action__btn .cart__btn .btn {
   width: 54px;
       height: 29px;
}
</style>
